import * as React from "react"
//import { motion, AnimatePresence } from "framer-motion";

import Layout from "../components/layoutEn"
import Warehouses from "../components/warehouses"
import { Link } from "gatsby"

//import cobertura from "../pdf/COBERTURA-GEOGRAFICA-Y-TIEMPOS-DE-TRANSITO-2022.pdf"
//import tiempos from "../pdf/TIEMPOS-RAIPACK-Y-NACIONAL-2022.pdf"

const NacionalPage = () => {

  return (
  <Layout
	seccion="nacional"	
	title="National"	
	linkTo="courier"
	>		
		<div id="courier" className="raicoContainer mt-30">
			<div className="row">
				<div id="expCol" className="col-sm-6">
					<div className={"expTxt bgLightBlue "}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
								
								<h2>Raico N@cional</h2></div>							
								
								<p className="text-white">National courier service up to 50 kg gross weight, designed for companies, SMEs and corporate customers.</p>

								<p className="text-white">We provide a wide delivery network throughout the country and online shipment tracking. </p>
								
								
								{/* <p className="text-white">Para solicitar su cotizacion enviar un mail a nuestro departamento comercial comercial@raiconet.com</p> */}
								
								<p className="text-white">Need more information?</p>
								
								<p className="text-white requisitos"
								style={{ marginTop: '10px' }}><Link to="/contacto">WE ARE HERE TO HELP</Link></p>
								
								{/*<p className="text-white requisitos"
								style={{ marginTop: '40px' }}><a href={tiempos} target="_blank">GEOGRAPHIC COVERAGE AND TRANSIT TIMES</a></p>
								*/}

								<p className="text-white requisitos"
								style={{ marginBottom: '30px' }}><Link to="/mediosDePago">PAYMENT METHODS</Link></p>
								
							</div>
					</div>
				</div>
				
					<div className={"col-sm-6 expImg raipack"} 
								  data-aos="zoom-in"								  
								  data-aos-duration="900">
						<div></div>
					</div>
			</div>
		</div>
		{/*<div id="expSimple" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-sm-6 pr-0">
					<div className={"expTxt bgLightBlue "}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}
		{/*
							<div className="col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
									<span>Pack Nacional Express</span>
								</div>
					
								
								<p className="text-white">Servicio de paquetería nacional de hasta 50 kg de peso
								bruto, destinado a empresas, Pymes y clientes
								corporativos.</p>
								
								<p className="text-white">Contamos con una amplia red de entrega en todo el país
								y seguimiento del envío online.</p> 
								
								<p className="text-white requisitos"><a href={cobertura}>COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
							</div>
					</div>
				</div>
				
				<div id="" className={"col-sm-6 expImg pack-nacional"} 
							  data-aos="zoom-in"								  
							  data-aos-duration="900">
					<div></div>
				</div>
			</div>			
		</div>
		*/ }
		<Warehouses />
  </Layout>
)
}
export default NacionalPage
